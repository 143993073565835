import React from "react";

// @mui material components
import { Card, CardContent } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "devComponents/Navbars/PublicNavbar";
import { useLocation } from "react-router-dom";

// Author page sections 
import Contact from "./JobApplication";



// Images
import bgImage from "assets/images/LandingPage/TechProfit.jpg";

function Index() {

    const location = useLocation();
    const { jobTitle } = location.state || {};
    const { department } = location.state || {};
    const { Description } = location.state || {};
    const { jobText } = location.state || {};
    const { PostDate } = location.state || {};
    return (
        <>


            <MKBox bgColor="white">
                <MKBox
                    minHeight="25rem"
                    width="100%"
                    sx={{
                        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                            `${linearGradient(
                                rgba(gradients.dark.main, 0.8),
                                rgba(gradients.dark.state, 0.8)
                            )}, url(${bgImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                    }}
                />
                <Card
                    sx={{
                        p: 2,
                        mx: { xs: 2, lg: 3 },
                        mt: -8,
                        mb: 4,
                        backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                        backdropFilter: "saturate(200%) blur(30px)",
                        boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    }}
                >
                    <Container>
                        <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
                            <CardContent>
                                <MKTypography
                                    variant="h1"
                                    color="black" textAlign="center"
                                    mb={1}
                                    sx={({ breakpoints, typography: { size } }) => ({
                                        [breakpoints.down("md")]: {
                                            fontSize: size["2xl"],
                                        },
                                    })}
                                >
                                    Apply As {jobTitle ? jobTitle : "Job"} {/* Show job title */}
                                </MKTypography>


                                <MKTypography
                                    variant="h5"
                                    color="black" textAlign="center"

                                    mb={1}
                                    sx={({ breakpoints, typography: { size } }) => ({
                                        [breakpoints.down("md")]: {
                                            fontSize: size["2xl"],
                                        },
                                    })}
                                >
                                    Congratulations for making it this far!
                                </MKTypography>
                                <MKTypography
                                    variant="body1"
                                    color="black" textAlign="center"
                                    px={{ xs: 6, lg: 12 }}
                                    mt={2}
                                >
                                    Here at DevHouse, we are always looking for talented people to join our team! If you are interested in joining our team.
                                </MKTypography>
                                <MKTypography
                                    variant="h6"
                                    color="black" 
                                    mt={5}

                                    mb={1}
                                    sx={({ breakpoints, typography: { size } }) => ({
                                        fontWeight: "bold",
                                        [breakpoints.down("md")]: {
                                            fontSize: size["2xl"],
                                        },
                                    })}
                                >
                                    {department ? `Department: ${department}` : ""}
                                </MKTypography>





                                <MKTypography
                                    variant="h6"
                                    color="black"
                                    mb={1}
                                    sx={({ breakpoints, typography: { size } }) => ({
                                        [breakpoints.down("md")]: {
                                            fontSize: size["xl"],
                                        },
                                    })}
                                >
                                    Posted From: {PostDate}
                                </MKTypography>

                                <MKTypography
                                    variant="h5" // Changed to "h5" for consistent size
                                    color="black" mb={1}
                                    sx={({ breakpoints, typography: { size } }) => ({
                                        [breakpoints.down("md")]: {
                                            fontSize: size["xl"], // Keep font sizes consistent across
                                        },
                                    })}
                                >
                                    Description:
                                </MKTypography>

                                <MKTypography
                                    variant="body1"
                                    color="black"
                                    mb={3}
                                    sx={({ breakpoints, typography: { size } }) => ({
                                        [breakpoints.down("md")]: {
                                            fontSize: size["xl"],
                                        },
                                    })}
                                >
                                    {Description}
                                </MKTypography>

                                <MKTypography
                                    variant="h5"
                                    color="black" mb={1}
                                    sx={({ breakpoints, typography: { size } }) => ({
                                        [breakpoints.down("md")]: {
                                            fontSize: size["xl"],
                                        },
                                    })}
                                >
                                    Requirements
                                </MKTypography>

                                <MKTypography
                                    variant="body1"
                                    color="black" mb={1}
                                    sx={({ breakpoints, typography: { size } }) => ({
                                        [breakpoints.down("md")]: {
                                            fontSize: size["xl"], // Use consistent sizing here as well
                                        },
                                    })}
                                >
                                    <ul style={{ paddingLeft: "1.5rem" }}> {/* Increase right-side indentation */}
                                        {jobText.map(({ text }, index) => (
                                            <li key={index} style={{ marginBottom: "0.5rem" }}>{text}</li>
                                        ))}
                                    </ul>
                                </MKTypography>

                            </CardContent>


                        </Grid>
                    </Container>

                    <Contact />
                </Card>
            </MKBox>
        </>
    );
}

export default Index;
