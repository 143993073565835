import React from 'react'
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { getFirestore, addDoc, doc, collection, serverTimestamp, onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useState, useEffect } from "react";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import Swal from "sweetalert2";
import Project from './Project';

const ProjectsList = () => {
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const db = getFirestore();
    useEffect(() => {
        let collectionRef = collection(db, 'pastProjects');
        onSnapshot(collectionRef, snapshot =>{
            let tempProjects = snapshot.docs.map(
                doc => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                }
            );
            let cleaned = tempProjects.filter(project => project.id != "Metadata");
            setProjects(cleaned);
        })
    }, []);
  return (
    <div className="ProjectsList">
        {
            projects.map(project => {
                return (
                    <Project Project={project}/>
                    );
            })
        }
    </div>
  )
}

export default ProjectsList