import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logoSVG';
import './footerStyle.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__columns">
                {/* Column 1: Company Name */}
                <div className="footer__column footer__company" style={{alignItems:"center"}}>
                    <h2>Dev House</h2>
                    <Logo />
                   
                    <p>We are a full-service software house dedicated to creating impactful digital experiences.</p>
                </div>

                {/* Column 2: Explore */}
                <div className="footer__column footer__explore">
                    <h3>Explore</h3>
                    <NavLink to="/Careers" className="footer__link">Carrers</NavLink>
                    <NavLink to="/contactus" className="footer__link">Contact Us</NavLink>
                </div>

                {/* Column 3: Services */}
                <div className="footer__column footer__contact">
                    <h3>Services</h3>
                    <ul>
                       
                        <li><a><i>Web Development</i></a></li>
                        <li><a><i>Mobile App Development</i></a></li>
                        <li><a><i>Software Solutions</i></a></li>
                        <li><a><i>Automation System</i></a></li>
                       
                       
                       
                    </ul>
                </div>

                {/* Column 4: Contact */}
                <div className="footer__column footer__contact">
                    <h3>Contact</h3>
                    <ul>
                        <li>
                            <a href="https://facebook.com" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i> Facebook
                            </a>
                        </li>
                        <li>
                            <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i> LinkedIn
                            </a>
                        </li>
                        <li>
                            <a href="https://youtube.com" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i> YouTube
                            </a>
                        </li>
                        <li>
                            <a href="https://wa.me/1234567890" target="_blank" rel="noreferrer">
                                <i className="fab fa-whatsapp"></i> WhatsApp
                            </a>
                        </li>
                    <li>
                        <i className="fas fa-phone-alt"></i> (+123) 456-7890
                        </li>
                        <li>

                        <i className="fas fa-envelope"></i> info@devhouse.com
                        </li>
                    </ul>
                    
                </div>
            </div>
        </footer>
    );
};

export default Footer;
