import React, { useEffect, useState } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import macbook from "assets/images/macbook.png";  // Default image if none is fetched
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import './featureDetails.scss';  // Ensure your styles are included here

const Feature = ({ Feature, Project }) => {
    const [ImgSrc, setImgSrc] = useState(macbook);  // Default image initially
    let storage = getStorage();

    useEffect(() => {
        GetImage();
    }, []);

    const GetImage = async () => {
        try {
            let imageRef = ref(storage, 'PastProjects/' + Project.id + '/features/' + Feature.name);
            let image = await getDownloadURL(imageRef);
            setImgSrc(image);
        } catch (error) {
            console.log("Error fetching image: ", error);
        }
    }

    return (
        <MKBox component="section" pt={6} my={6}>
            <Container>
                <Grid container className='projectCard'>
                <Grid item xs={12} md={5} sx={{ ml: "auto" }} className="feature-image">
                        <MKBox position="relative" className="image-container">
                            <MKBox
                                component="img"
                                src={ImgSrc || macbook}
                                alt={Feature.name || "Feature Image"}
                                className="hover-image"
                                sx={{
                                    maxWidth: "20em",  
                                    maxHeight: "20em",  
                                    width: "100%",
                                    transition: "transform 0.3s ease",  
                                    borderRadius:"7px",
                                    "&:hover": {
                                        transform: "scale(1.1)",
                                        
                                    },
                                }}
                            />
                        </MKBox>
                    </Grid>
                    {/* Feature Description Section */}
                    <Grid item xs={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                        <MKTypography variant="h4">{Feature.name}</MKTypography>
                        <MKTypography variant="body2" color="text" mb={3}>
                            {Feature.description}
                        </MKTypography>
                    </Grid>

                    {/* Image Section with Hover Effect */}
                
                </Grid>
            </Container>
        </MKBox>
    )
}

export default Feature;
