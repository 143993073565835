 
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";
import './DesignBlockStyle.scss';
// Data
import data from "./data/designBlocksData";

function DesignBlocks() {
  const renderData = data.map(({ number, title, description, items }) => (
    <div style={{ position: 'relative' }}> {/* Parent container */}
      {/* Number behind the card */}
      <MKBox
  sx={{
    width: { xs: '30px', sm: '50px' }, 
    height: { xs: '30px', sm: '50px' }, 
    borderRadius: '50%',
    backgroundColor: '#2882e2 ',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: { xs: '-32px', sm: '-65px' }, 
    top: '20px',
    zIndex: 1,
  }}
>
  {number}
</MKBox>

<MKBox
  sx={{
    position: 'absolute',
    height: { xs: '120%', sm: '120%' }, 
    left: { xs: '-20px', sm: '-40px' }, 
    top: 0,
    bottom: 0,
    width: '2px',
    backgroundColor: '#1f8fe6',
    zIndex: 0,
  }}
></MKBox>

  
      <div >
        <Grid container spacing={2} sx={{ position: 'relative' }} key={title}>
          <Grid item xs={12} lg={12}>
            <MKBox  top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                {title}
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary"  pr={2}>
                {description}
              </MKTypography>
            </MKBox>
            <div className="design-blocks__item">
            <Grid container spacing={3}>
              {items.map(({ text }) => (
                <Grid item xs={12} md={12} sx={{ mb: 2 }} key={text}>
                  <MKTypography variant="body1" fontWeight="bold" className="blockPoints" mb={1}>
                    <li>{text}</li>
                  </MKTypography>
                </Grid>
              ))}
            </Grid>
              </div>
          </Grid>
          
        </Grid>
      </div>
    </div>
  ));
  
  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="outline"
            color="dark"
            badgeContent="A Smooth Development Process"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            The Typical Cycle at The DevHouse
          </MKTypography>
          <MKTypography variant="body1" color="text">
            This is the typical proccess of creating a solution with The DevHouse.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default DesignBlocks;
