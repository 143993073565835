import React, { useEffect, useState } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import macbook from "assets/images/macbook.png";
import Swal from 'sweetalert2';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import './projectDetails.scss';

const Project = ({ Project }) => {
  const [ImgSrc, setImgSrc] = useState("");
  let storage = getStorage();
  let navigate = useNavigate();

  useEffect(() => {
    const GetImage = async () => {
      Swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
      });
      
      try {
        let imageRef = ref(storage, 'PastProjects/' + Project.id + '/CoverImage');
        let image = await getDownloadURL(imageRef);
        setImgSrc(image);
        Swal.close();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to load the image!',
        });
      }
    };

    GetImage();
  }, [Project.id, storage]);

  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container className='projectCard' onClick={() => navigate("/PastProjects/Details", { state: { project: Project } })}>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }} className="project-image">
            <MKBox position="relative" className="image-container">
              <MKBox
                component="img"
                src={ImgSrc}
                alt={macbook}
                className="hover-image"
              />
            </MKBox>
          </Grid>

          <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">{Project.title}</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              {Project.description}
            </MKTypography>
            <Grid container spacing={1}>
              {/* <Grid item xs={8}>
                {Project.link !== "" && (
                  <a href={Project.link} tsarget="_blank" rel="noopener noreferrer" className="visit-Live-link">
                    Visit Live
                  </a>
                )}
              </Grid> */}
              <Grid item xs={4}>
                <MKButton variant="standard" color="info" sx={{ height: "100%" }} onClick={() => navigate("/PastProjects/Details", { state: { project: Project } })}>
                  Learn more
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default Project;
