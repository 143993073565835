import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Swal from "sweetalert2";
import { getFirestore, addDoc, doc, collection, serverTimestamp, setDoc, increment } from "firebase/firestore";

import './ConsultationForm.scss';

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [meetingPlatform, setMeetingPlatform] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [meetingDate, setMeetingDate] = useState("");

  const db = getFirestore();

  const handleSubmit = async () => {
    if (name === "" || email === "" || secondaryContact === "" || subject === "" || message === "" || meetingPlatform === "" || meetingTime === "" || meetingDate === "") {
      Swal.fire({
        title: "Error",
        text: "Please fill all the fields",
        icon: "error",
        confirmButtonText: "OK"
      });
      return;
    }

    if (!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
      Swal.fire({
        title: "Error",
        text: "Please enter a valid email",
        icon: "error",
        confirmButtonText: "OK"
      });
      return;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const collectionRef = collection(db, "consultationProgram");
    const res = await addDoc(collectionRef, {
      name,
      email,
      secondaryContact,
      subject,
      message,
      meetingPlatform,
      meetingTime,
      meetingDate,
      createdAt: serverTimestamp(),
      timezone
    });

    const docRef = doc(db, "consultationProgram", "consultationProgramData");
    await setDoc(docRef, { count: increment(1) }, { merge: true });

    document.getElementById("consultationForm").reset();
    Swal.fire({
      title: "Success",
      text: `Your request has been submitted. Your Request ID is ${res.id}. We will contact you at the earliest.`,
      icon: "success",
      confirmButtonText: "OK"
    });
  };

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }} className="contact-section">
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
            className="form-container"
          >
            <MKBox id="consultationForm" component="form" p={2} method="post" className="form-box">
              <MKBox px={3} py={{ xs: 2, sm: 6 }} className="form-header">
                <MKTypography variant="body1" color="text" mb={2} className="form-title">
                  Fill out the form below and click send to complete the request.
                </MKTypography>
              </MKBox>
              <MKBox pt={0.5} pb={3} px={3} className="form-body">
                <Grid container>
                  <Grid item xs={12} md={6} pr={1} mb={6} className="form-item">
                    <MKInput
                      variant="standard"
                      label="My name is"
                      placeholder="Full Name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                      id="name"
                      className="form-input"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} pr={1} mb={6} className="form-item">
                    <MKInput
                      id="subject"
                      variant="standard"
                      label="I'm looking for"
                      placeholder="Subject"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setSubject(e.target.value)}
                      className="form-input"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} pr={1} mb={6} className="form-item">
                    <MKInput
                      id="email"
                      variant="standard"
                      label="My primary email is"
                      placeholder="example@something.com"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-input"
                    />
                  </Grid>
                  <Grid itemxs={12} md={6} pr={1} mb={6} className="form-item">
                    <MKInput
                      id="secondaryContact"
                      variant="standard"
                      label="My secondary contact info is"
                      placeholder="Any other contact info that can help us reach you (e.g. phone number, social media)"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setSecondaryContact(e.target.value)}
                      className="form-input"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} pr={1} mb={6} className="form-item">
                    <MKInput
                      id="meetingPlatform"
                      variant="standard"
                      label="I'd like to have the meeting on"
                      placeholder="Zoom, Google Meet, Skype, etc."
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setMeetingPlatform(e.target.value)}
                      className="form-input"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} pr={1} mb={6} className="form-item">
                    <MKInput
                      id="meetingDate"
                      type="date"
                      variant="standard"
                      label="I'm free to schedule a meeting on this day"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setMeetingDate(e.target.value)}
                      className="form-input"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} pr={1} mb={6} className="form-item">
                    <MKInput
                      id="message"
                      variant="standard"
                      label="Describe your subject in detail"
                      placeholder="Describe what you need, or the nature of your request"
                      InputLabelProps={{ shrink: true }}
                      fullWidth

                      rows={6}
                      onChange={(e) => setMessage(e.target.value)}
                      className="form-input"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} pr={1} mb={6} className="form-item">
                    <MKInput
                      id="meetingTime"
                      type="time"
                      variant="standard"
                      label="at this time"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setMeetingTime(e.target.value)}
                      className="form-input"
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" textAlign="center">
                  <MKButton

                    onClick={handleSubmit}
                    className="submit-button"
                  >
                    Send Request
                  </MKButton>
                </Grid>



              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
