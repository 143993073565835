// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { getFirestore, addDoc, doc, collection, serverTimestamp, onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
// import useState and useEffect to handle the form state and submission
import { useState, useEffect } from "react";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import './JobAppliactionStyle.scss'
import Swal from "sweetalert2";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [currentJob, setCurrentJob] = useState("");
  const [availableToStart, setAvailableToStart] = useState("");

  const db = getFirestore();
  const storage = getStorage();

  const handleSubmit = async () => {
    console.log(name , email,currentJob,availableToStart,secondaryContact,subject,message)
    if (name === "" || email === "" || secondaryContact === "" || subject === "" || message === ""
      || currentJob === "" || availableToStart === "") {
      Swal.fire({
        title: "Error",
        text: "Please fill all the fields",
        icon: "error",
        confirmButtonText: "OK"
      });
      return;
    }
    // check email with regex

    if(!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
      Swal.fire({
        title: "Error",
        text: "Please enter a valid email",
        icon: "error",
        confirmButtonText: "OK"
      });
      return;
    }


    // get the user's timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // set the document in the database
    addDoc(collection(db, "jobApplications"), {
      name,
      email,
      secondaryContact,
      subject,
      message,
      currentJob,
      availableToStart,
      createdAt: serverTimestamp(),
      timezone
    }).then(async (res) => { 
      // reset the form
      
      // upload the resume and other files in input "resumeFiles" 
      const resumeFiles = document.getElementById("resumeFiles").files;
      if (resumeFiles.length > 0) {
        console.log("resumeFiles", resumeFiles)
        // upload the all the files
        for (let i = 0; i < resumeFiles.length; i++) {
          const file = resumeFiles[i];
          const fileName = file.name;
          const fileType = file.type;
          const filePath = "jobApplications/" + res.id + "/" + fileName;
          const fileRef = ref(storage, filePath);
          uploadBytes(fileRef, file).then((res) => {
            console.log("Uploaded file: " + fileName, res);
          })
          .catch((err) => {
            console.log("Error uploading file: " + fileName, err)
          });
        }
      }
      document.getElementById("jobApplicationForm").reset();
      Swal.fire({
        title: "Success",
        text: "Your request has been submitted. Your Request ID is " + res.id + ". We will get contact you at the earliest.",
        icon: "success",
        confirmButtonText: "OK"
      });

    }
    ).catch(error => {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK"
      });
    });
  }


  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }} >
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <MKBox component="form" p={2}  id="jobApplicationForm" method="post" className="input_Form">
              <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                <MKTypography variant="body1"  color="text" mb={2}>
                  Fill out the form below and click send to complete the request.
                </MKTypography>
              </MKBox>
              <MKBox pt={0.5} pb={3} px={3}  >
                <Grid container>
                <Grid item xs={12} sm={6} pr={1} mb={6}>
                <MKInput
                      variant="standard"
                      label="My name is"
                      placeholder="Full Name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} pr={1} mb={6}>
                  <MKInput
                      variant="standard"
                      label="I'm interested in working with"
                      placeholder="Department(s) (e.g. Development, Marketing, Sales)"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} pr={1} mb={6}>
                  <MKInput
                      variant="standard"
                      label="My primary email is"
                      placeholder="example@something.com"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} pr={1} mb={6}>
                  <MKInput
                      variant="standard"
                      label="My secondary contact info is"
                      placeholder="Any other contact info that can help us reach you. (e.g. phone number, IMessage, FaceBook, Github etc.)"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      rows={4}
                      onChange={(e) => setSecondaryContact(e.target.value)}

                    />
                  </Grid>
                  <Grid item xs={12} sm={6} pr={1} mb={6}>
                  <MKInput
                      variant="standard"
                      label="Your message, why do you want to join our team?"
                      placeholder="I want to say that..."
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      
                      rows={6}
                      onChange={(e) => setMessage(e.target.value)}

                    />
                  </Grid>
                  <Grid item xs={12} sm={6} pr={1} mb={6}>
                  <MKInput
                      variant="standard"
                      label="My Current Job Title is"
                      placeholder="Example: Senior Software Engineer, Student, etc."
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setCurrentJob(e.target.value)}

                    />
                  </Grid>
                  <Grid item xs={12} sm={6} pr={1} mb={6}>
                  <MKInput
                      type="date"
                      variant="standard"
                      label="Available to Start"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setAvailableToStart(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={7} pr={1} mb={6}>
                    <label style={{ marginRight: "1em" }}>Upload your resume and any other relevant files</label>
                    <input className="upload-file"  id="resumeFiles" style={{ marginLeft: "auto" }} type="file" name="file" multiple />
                  </Grid>

                  <Grid
                    container
                    item
                   
                    justifyContent="center"
                    textAlign="right"
                    ml="auto"
                  >
                  <MKButton className="submit-button" onClick={() => handleSubmit()}>

                      Send Application
                  </MKButton>
                    
                      
                     
                   
                  </Grid>
                </Grid>
              </MKBox>
            </MKBox>

          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
