
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import TheThreePrincipals from "./sections/TheThreePrincipals";
import Information from "./sections/Information";
import DesignBlocks from "./sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Download from "./sections/Download";

// Presentation page components
import './iconsStyles.scss';
import footerRoutes from "footer.routes";
import { useNavigate } from "react-router-dom";

// Images
// import bgImage from "assets/images/bg-presentation.jpg";
import bgImage from "assets/images/LandingPage/RedLedKeyboard.jpg";
import Animated from '../../../../src/assets/images/11.gif';
import DevIcon from '../../../../src/assets/images/Subject.png';
import DevIcon2 from '../../../../src/assets/images/Subject2.png';
import DevIcon3 from '../../../../src/assets/images/image.png';



import MKButton from "components/MKButton";
import { Padding } from "@mui/icons-material";
import AnimatedBackground from "components/HomeBg/AnimatedBackground";
import text from "assets/theme/components/button/text";

function Presentation() {
  let navigate = useNavigate();
  return (
    <>
      <MKBox
        minHeight="105vh"
        width="100%"
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems="center"
      >
        <AnimatedBackground />
        <Container>
          {/* Apply spacing and responsive layout */}
          <Grid container spacing={3} alignItems="center">

            {/* Left Column for Typography */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ textAlign: { xs: "center", md: "left" } }}  // Center on small screens, left align on larger
            >
              <MKTypography
                variant="h1"
                color="white"
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"], // Smaller font size for mobile
                  },
                  fontWeight: "bold",
                  textTransform: "uppercase",
                })}
              >
                The DevHouse
              </MKTypography>

              <MKTypography
                variant="body1"
                color="white"
                mb={2}
                textAlign="center"
                px={{ xs: 3, md: 0 }}  // Adjust padding for smaller screens
                sx={{ maxWidth: { xs: "100%", md: "650px" } }}  // Full width on mobile
              >
                "The one stop shop for all your software development and tech needs."
              </MKTypography>

              {/* Center the button on small screens */}
              <MKBox display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
                <MKButton
                  variant="outlined"
                  color="white"
                  sx={{
                    width: "100%",
                    maxWidth: "300px",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    border: "1px solid white",
                    mt: { xs: 2, md: 4 },  // Add margin-top for mobile
                  }}
                  onClick={() => navigate("/ConsultationProgram")}
                >
                  BOOK FREE CONSULTATION SESSION
                </MKButton>
              </MKBox>
            </Grid>

            {/* Right Column for Image/Icon */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}  // Center image on small screens
            >
              <img
                src={DevIcon3}
                alt="Development Icon"
                style={{
                  width: '100%',
                  maxWidth: '500px',  // Maximum width for larger screens
                  height: 'auto',
                }}
              />
            </Grid>

          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <TheThreePrincipals />
        <Information />
        <DesignBlocks />


        {/*   <Download /> */}

      </Card>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Presentation;
