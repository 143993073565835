import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "../../../src/assets/images/48656.jpg";
import "./careerStyle.scss";

function getDatePosted(postedDate) {
  const currentDate = new Date();
  const timeDiff = Math.abs(currentDate - new Date(postedDate));
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (daysDiff === 0) return "Today";
  else if (daysDiff === 1) return "Yesterday";
  else return `${daysDiff} days ago`;
}

function DesignBlocks() {
  const [data, setData] = useState([]); // State to store fetched data
  const db = getFirestore();

  // Fetch vacancies data from Firestore
  useEffect(() => {
    const fetchVacancies = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "vacancies"));
        const vacancies = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(vacancies); // Store the fetched data in state
      } catch (error) {
        console.error("Error fetching vacancies: ", error);
      }
    };

    fetchVacancies();
  }, []);

  const renderData = data.map(({ id, JobTitle, Department, requirements, postedDate, description }) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
      <Card className="card-container" sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <CardContent sx={{ flexGrow: 1 }}>
          <MKTypography variant="h5" fontWeight="bold" mb={1}>
            {JobTitle}
          </MKTypography>
          <MKTypography variant="subtitle1" color="text.secondary" mb={2}>
            {Department}
          </MKTypography>
          <MKTypography variant="body2" color="text.secondary">
            {getDatePosted(postedDate)}
          </MKTypography>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", pb: 2 }}>
          <Button
            className="apply-btn"
            component={Link}
            to="/Jobs"
            variant="contained"
            fullWidth
            state={{
              id: id,
              jobTitle: JobTitle,
              department: Department,
              jobText: requirements,
              Description: description,
              PostDate: getDatePosted(postedDate),
            }}
          >
            Apply for {JobTitle}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  ));

  return (
    <MKBox component="section" py={6}>
      <MKBox
        minHeight="35rem"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(rgba(gradients.dark.main, 0.3), rgba(gradients.dark.state, 0.3))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          filter: "blur(1px)", // Add this line for blur
        }}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
          >
            <MKTypography variant="h2" fontWeight="bold">
              Your Opportunity to Join DevHouse
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Discover the path to landing your dream job at DevHouse and becoming part of a team that creates innovative solutions.
            </MKTypography>
          </Grid>
        </Container>
        <Container sx={{ mt: 6 }}>
          <Grid container spacing={3}>
            {renderData}
          </Grid>
        </Container>
      </Card>
    </MKBox>
  );
}

export default DesignBlocks;
