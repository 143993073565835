// Material Kit 2 React components
import React, { useEffect, useState } from 'react';
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "../../../Routes/publicRoutes";
import { useLocation } from 'react-router-dom';
import Logo from "assets/images/logos/logo-05_Cropped.png";
import './NavStyle.scss';

function Index() {
  const [navColor, setNavColor] = useState('backdrop-filter: blur(50px);'); 
  const location = useLocation(); 
  
  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === "/") { 
        if (window.scrollY + 200 > window.innerHeight) {
          setNavColor('#2882e2 '); 
        } else {
          setNavColor(); 
        }
      }
    };

    if (location.pathname === "/") { 
      window.addEventListener('scroll', handleScroll);

    } else {
      setNavColor('#2882e2 '); 
    }

    return () => {
      window.removeEventListener('scroll', handleScroll); 
    };
  }, [location.pathname]); 
  return (
    <MKBox className="NavCard" style={{ backgroundColor: navColor }}    py={0.25}>
      <DefaultNavbar
        routes={routes}
        Logo={Logo}
        action={{
          type: "internal",
          route: "/ConsultationProgram",
          label: "Start Now",
          color: "white",
        }}
        brand={"The DevHouse, by HB-TEC"}
        transparent
        relative
        light
        center
        sticky={true}
      />
    </MKBox>
  );
}

export default Index;
