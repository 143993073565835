import React from 'react'
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import DefaultNavbar from "devComponents/Navbars/PublicNavbar";
import bgImage from "assets/images/LandingPage/PastProjects.jpg";
import MKTypography from "components/MKTypography";
import ProjectsList from './ProjectsList';

const Index = () => {

    return (
        <>
            <DefaultNavbar />
            <MKBox bgColor="white">
                <MKBox
                    minHeight="35rem"
                    width="100%"
                    sx={{
                        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                            `${linearGradient(
                                rgba(gradients.dark.main, 0.8),
                                rgba(gradients.dark.state, 0.8)
                            )}, url(${bgImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                    }}
                />
                <Card
                    sx={{
                        p: 2,
                        mx: { xs: 2, lg: 3 },
                        mt: -8,
                        mb: 4,
                        backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                        backdropFilter: "saturate(200%) blur(30px)",
                        boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    }}
                >
                    <Container>
                        <Grid container mb={3} item xs={12} lg={7} justifyContent="center" mx="auto">
                            <MKTypography
                                variant="h1"
                                color="dark"
                                textAlign="center"

                                mb={1}
                                sx={({ breakpoints, typography: { size } }) => ({
                                    [breakpoints.down("md")]: {
                                        fontSize: size["3xl"],
                                    },
                                })}
                            >
                                A Sample of our Past Projects
                            </MKTypography>
                            <MKTypography
                                variant="h5"
                                color="dark"
                                textAlign="center"

                                mb={1}
                                sx={({ breakpoints, typography: { size } }) => ({
                                    [breakpoints.down("md")]: {
                                        fontSize: size["3xl"],
                                    },
                                })}
                            >
                                We've developed a lot of projects over the years, these are just a few!
                            </MKTypography>
                        </Grid>
                    </Container>
                    <ProjectsList/>
                </Card>
            </MKBox>
        </>
    );
}

export default Index; 