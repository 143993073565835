/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
// import bgFront from "assets/images/rotating-card-bg-front.jpeg";
// import bgBack from "assets/images/rotating-card-bg-back.jpeg";
import bgFront from "assets/images/LandingPage/TechProfit.jpg";
import bgBack from "assets/images/LandingPage/FuturisticGirl.jpg";

function Information() {
  return (
    <MKBox  py={6} my={6}>
      <Container>
        <Grid container item xs={12} spacing={3} alignItems="center" >
          {/* <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                color="dark"
                icon="touch_app"
                title={
                  <>
                    About Our
                    <br />
                    FREE Consultation Program
                  </>
                }
                description="This is the first step to get your business online. It's also a chance for us to get aquainted and for you to ask us any questions you may have... and its FREE so why not?"
              />
              <RotatingCardBack
                image={bgBack}
                color="dark"
                title="Book Your Session"
                description="Book a consultation session at the time of your choice and one of our project managers will contact you at the earliest to confirm."
                action={{
                  type: "internal",
                  route: "/ConsultationProgram",
                  label: "Book Now",
                }}
              />
            </RotatingCard>
          </Grid> */}
          <Grid item xs={12} lg={12}  >
            <Grid container className="businessCardContainer" >
              <Grid item xs={12 }   md={5} className="businessCard">
                <DefaultInfoCard
                  icon="content_copy"
                  title="Explore The Possibilities"
                  description="Have a friendly chat with one of our project managers and learn how we can help you with your business. Two heads are better than one, let's think about it together!"
                />
              </Grid>
              <Grid item xs={12} md={5} className="businessCard">
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="For Established Businesses"
                  description="If you are already a well established business, we can optimize and automate your business cycle and help you grow."
                />
              </Grid>
            </Grid>
            <Grid container   className="businessCardContainer">
              <Grid item xs={12} md={5} className="businessCard">
                <DefaultInfoCard
                  icon="price_change"
                  title="Save Time & Money"
                  description="We are 100% hassle free. We take care of everything from the planning to the implementation to ongoing maintenance, so you can focus on the things that matter to you."
                />
              </Grid>
              <Grid item xs={12} md={5} className="businessCard">
                <DefaultInfoCard
                  icon="devices"
                  title="For New Businesses"
                  description=" If you are a new bussiness or just starting out, we can turn your ideas into reality, fast and reliably because we already know what works and what doesn't."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
